// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-me-tsx": () => import("./../../../src/pages/me.tsx" /* webpackChunkName: "component---src-pages-me-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-bouncingborders-tsx": () => import("./../../../src/pages/work/bouncingborders.tsx" /* webpackChunkName: "component---src-pages-work-bouncingborders-tsx" */),
  "component---src-pages-work-dirt-tsx": () => import("./../../../src/pages/work/dirt.tsx" /* webpackChunkName: "component---src-pages-work-dirt-tsx" */),
  "component---src-pages-work-foliotune-tsx": () => import("./../../../src/pages/work/foliotune.tsx" /* webpackChunkName: "component---src-pages-work-foliotune-tsx" */),
  "component---src-pages-work-james-thomson-gorwill-portfolio-tsx": () => import("./../../../src/pages/work/james-thomson-gorwill-portfolio.tsx" /* webpackChunkName: "component---src-pages-work-james-thomson-gorwill-portfolio-tsx" */),
  "component---src-pages-work-middletons-mobility-tsx": () => import("./../../../src/pages/work/middletons-mobility.tsx" /* webpackChunkName: "component---src-pages-work-middletons-mobility-tsx" */),
  "component---src-pages-work-oak-tree-tsx": () => import("./../../../src/pages/work/oak-tree.tsx" /* webpackChunkName: "component---src-pages-work-oak-tree-tsx" */),
  "component---src-pages-work-pitchers-tsx": () => import("./../../../src/pages/work/pitchers.tsx" /* webpackChunkName: "component---src-pages-work-pitchers-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

